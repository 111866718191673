import {Link} from "gatsby"
import React, {useState} from "react"
import {Accordion, Card, Container} from "react-bootstrap"
import Divider from "../components/common/divider"
import FullWidthCta from "../components/common/FullWidthCta"
import WayOfWork from "../components/common/WayOfWork"
import Layout from "../components/site-wide/layout/layout"
import constants from "../utils/constants"
import classnames from 'classnames';

const BitoviPage = () => {

    const [activeKey, setActiveKey] = useState('0');

    return (
        <Layout
            title={constants.BITOVI.META.TITLE}
        >
            <Container className="my-5 py-5">
                <div className="text-center mb-5">
                    <h1 className="mt-5">Битово-ключарски услуги</h1>
                    <Divider addClassName="mb-5"/>
                    <p>Битови ключарски услуги, аварийна ключарска помощ</p>
                </div>


                <Accordion activeKey={activeKey}>
                    <Card>
                        <Accordion.Toggle eventKey='0' as={Card.Header} onClick={() => setActiveKey('0')}
                                          className={classnames('bitovi', {"active": '0' === activeKey})}>
                            Аварийно отключване

                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                <p>Ако имате проблем с отварянето или отключването на вратата във Вашия дом, офис,
                                    апартамент, гараж, къща, вила,мазе и др.- не се колебайте да ни потърсите. Нашите
                                    висококвалифицирани ключари ще се отзоват максимално бързо на посочения от Вас
                                    адрес.</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle eventKey='1' as={Card.Header} onClick={() => setActiveKey('1')}
                                          className={classnames('bitovi', {"active": '1' === activeKey})}>
                            Изработка на битови ключове
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='1'>
                            <Card.Body>
                                Ключарският ни център разполага с висок клас италиански машини, което гарантира
                                прецизната изработка на ключове. Може да се направят дубликати на почти всички видове
                                секретни, касови, ямкови, помпови, туболарни, сейфови и други заключващи механизми и
                                системи.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>


                    <Card>
                        <Accordion.Toggle eventKey='2' as={Card.Header} onClick={() => setActiveKey('2')}
                                          className={classnames('bitovi', {"active": '2' === activeKey})}>
                            Продажба на брави, патрони и други аксесоари
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='2'>
                            <Card.Body>
                                Ключарският ни център разполага с богат асортимент от касови и секретни брави, и
                                всякакви видове патрони, катинари и заключващи механизми
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle eventKey='3' as={Card.Header} onClick={() => setActiveKey('3')}
                                          className={classnames('bitovi', {"active": '3' === activeKey})}>
                            Ремонт, монтаж и подмяна на брави, патрони, шпионки, бронировки, сейфове
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='3'>
                            <Card.Body>
                                <ul className="key-list house">
                                    <li>
                                        Ключарският ни център извършва всякакви монтажи, демонтажи и ремонти на посочен
                                        от Вас адрес
                                    </li>
                                    <li>
                                        Преикокдиране на всички видове секретни и ямкови патрони, касови, помпови,
                                        туболарни, сейфови и други заключващи системи
                                    </li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>


                    <Card>
                        <Accordion.Toggle eventKey='4' as={Card.Header} onClick={() => setActiveKey('4')}
                                          className={classnames('bitovi', {"active": '4' === activeKey})}>
                            Копиране на дистанционни, чипове и карти
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='4'>
                            <Card.Body>
                                <ul className="key-list house">
                                    <li>
                                        Изработка, копиране, генериране и вписване на максимално възможен диапазон от
                                        дистанционни за гаражни врати, бариери и ролетни щори
                                    </li>
                                    <li>
                                        Копиране на RFID чипове, карти и ibutton чипове за достъп.
                                    </li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle eventKey='5' as={Card.Header} onClick={() => setActiveKey('5')}
                                          className={classnames('bitovi', {"active": '5' === activeKey})}>
                            Консултация и оглед от високо квалифициран ключар
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='5'>
                            <Card.Body>
                                Нашия екип ще направи беседа с Вас и ще ви помогне да вземете най-правилното решение във
                                вашия случай! Ако не знаете какъв патрон или брава да поставите на Вашето жилище за
                                Вашата най-голяма сигурност- не се колебайте да ни <Link to="/kontakti"
                                                                                         className="text-gold">попитате</Link>!
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </Container>
            <FullWidthCta/>
            <WayOfWork/>
        </Layout>
    )
}

export default BitoviPage;

export const query = graphql`
    query BitoviQuery {
        img: file(relativePath: {eq: "businesses/clients.jpg"}) {
            childImageSharp {
                fluid(quality: 100) {
                    src
                }
            }
        }
    }
`;
